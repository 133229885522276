import { t } from '@lib/i18n';
import Typography, { getTypographyClass } from '@ui/Typography';
import { AppFunctional } from '@widgets/app-functional';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import styled from 'windstitch';
import { useModal } from '@ui/Modal';
import { RequestFeedback } from '@features/request-feedback';

const Container = styled.div(clsx(
  'flex',
  'flex-col',
  'bg-tertiary200',
  'h-full',
  'fixed',
  'top-0',
  'pl-4',
  'border border-solid border-tertiary200',
));

const Banner = ({ className }) => {
  const { openModal } = useModal('request-demo');

  return (
    <Container className={className}>
      <RequestFeedback.Demo.Modal />
      <div className="col-4 flex h-full flex-col justify-between pr-4">
        <div className="col-4 flex flex-col pr-4">
          <Typography variant="caption2" color="secondary100" className="mt-[168px] uppercase">
            {t('registration.banner.title')}
          </Typography>
          <Typography variant="header5" color="secondary400" className="mt-1">
            {t('registration.banner.subtitle')}
          </Typography>
          <AppFunctional className="mt-5" />
        </div>
      </div>
    </Container>
  );
};

export default Banner;
